* {
  -webkit-font-smoothing: antialiased; }

body {
  font-family: ProximaSoft-Regular;
  font-weight: normal;
  font-style: normal;
  color: #ffffff; }

a,
a:active,
a:visited {
  text-decoration: none;
  color: inherit; }

.navigation {
  font-family: ProximaSoft-Medium;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase; }

.navigation__menu {
  font-family: ProximaSoft-Medium;
  font-weight: normal;
  font-style: normal;
  line-height: 42px;
  letter-spacing: 2px; }
  @media (max-width: 768px) {
    .navigation__menu {
      font-size: 24px;
      line-height: 2.5em;
      letter-spacing: 3px;
      white-space: nowrap; } }

.navigation__copyright {
  font-family: ProximaSoft-Regular;
  font-weight: normal;
  font-style: normal;
  line-height: 18px; }

.navigation__copyright {
  font-size: 15px; }

.main__header h1 {
  font-family: ProximaSoft-Light;
  font-weight: normal;
  font-style: normal;
  font-size: 48px;
  line-height: 1.5em; }
  @media (max-width: 768px) {
    .main__header h1 {
      font-size: 42px;
      line-height: 60px; } }

.main__about-button,
.main__career-button {
  font-family: ProximaSoft-Medium;
  font-weight: normal;
  font-style: normal;
  color: #EFEFEF;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 16px;
  line-height: 19px; }

.section-title {
  font-family: ProximaSoft-Medium;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 16px;
  line-height: 4em;
  color: #C7E7DB; }

.about__segment-text {
  color: #EFEFEF;
  font-size: 18px;
  line-height: 1.66em; }
  @media (max-width: 768px) {
    .about__segment-text {
      text-align: center; } }

.careers_intro p {
  color: #EFEFEF;
  font-size: 18px;
  line-height: 30px; }

.careers__job-title {
  color: #FFFFFF;
  font-size: 24px;
  line-height: 1.25em;
  white-space: nowrap; }
  @media (max-width: 350px) {
    .careers__job-title {
      font-size: 21px; } }

.careers__job-location {
  color: #C7E7DB;
  font-size: 18px;
  line-height: 1.66em; }

.contact__info {
  color: #efefef;
  font-size: 24px;
  line-height: 29px; }
  @media (max-width: 768px) {
    .contact__info {
      font-size: 20px;
      line-height: 24px; } }
  @media (max-width: 326px) {
    .contact__info {
      font-size: 18px; } }

.career_intro {
  font-size: 18px;
  line-height: 30px; }

.duties__duty {
  font-size: 18px;
  line-height: 30px; }

.main__header.--duties h1 {
  line-height: 1.2em; }
  @media (min-width: 1370px) {
    .main__header.--duties h1 {
      white-space: nowrap; } }

.copyright {
  font-size: 15px;
  line-height: 18px;
  opacity: 0.5; }

@media (min-width: 910px) {
  .hidden-break {
    display: none; } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  padding-top: 0;
  background-color: #101322;
  position: relative; }

@media (min-width: 768px) {
  .mobile {
    display: none !important; } }

@media (max-width: 768px) {
  .desktop {
    display: none !important; } }

section.page {
  overflow: hidden; }

div.container {
  max-width: 1200px;
  margin: 0 auto; }
  @media (max-width: 1440px) {
    div.container {
      width: auto;
      margin-left: 112px; } }
  @media (max-width: 768px) {
    div.container {
      max-width: calc(100% - 64px);
      margin: 0 32px; } }

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1; }

.parallax {
  position: absolute;
  z-index: -2;
  background-image: url("../assets/bg/gwiazdy2x.jpg");
  background-size: cover;
  background-attachment: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0; }

@media (max-width: 768px) {
  div.navigation__container {
    margin: 0;
    max-width: 100%; } }

section.navigation {
  padding-top: 73px; }
  @media (max-width: 768px) {
    section.navigation {
      position: fixed;
      z-index: 2;
      width: 100%;
      top: 0;
      padding-top: 0px; }
      section.navigation nav {
        padding-top: 10px;
        padding-left: 32px;
        padding-right: 32px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center; }
      section.navigation.sticky nav {
        display: flex;
        width: 100%;
        top: 0;
        padding-top: 5px;
        background-color: rgba(11, 11, 19, 0.9);
        padding-bottom: 5px;
        z-index: 2; }
        section.navigation.sticky nav .navigation__logo {
          order: -1; } }
  section.navigation nav {
    transition: all 0.5s; }
    @media (max-width: 768px) {
      section.navigation nav {
        margin-bottom: 0; } }

.navigation__logo {
  transition: all 0.3s;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  z-index: 2;
  cursor: pointer; }
  @media (max-width: 768px) {
    .navigation__logo {
      justify-content: flex-start;
      margin-bottom: 0;
      position: unset;
      transform: scale(0.6);
      margin-left: -24px; } }
  @media (min-width: 768px) {
    .navigation__logo {
      padding: 10px 15px 10px 0;
      margin-left: -80px; } }
  .navigation__logo .navigation__logo-icon {
    margin-right: 35px;
    max-width: 35px;
    height: auto; }
    @media (max-width: 768px) {
      .navigation__logo .navigation__logo-icon {
        position: inherit;
        left: 0;
        margin-right: 35px;
        margin-left: unset; } }
  .navigation__logo .navigation__logo-type {
    max-width: 70px;
    height: auto;
    float: left;
    position: relative; }
    .navigation__logo .navigation__logo-type:after {
      display: block;
      content: "";
      clear: both;
      position: absolute; }

.navigation__menu {
  position: fixed;
  z-index: 1;
  padding-top: 170px; }
  .navigation__menu .navigation__menu-links {
    list-style: none;
    opacity: 0.8;
    margin-bottom: 200px; }
  .navigation__menu .navigation__menu-links {
    cursor: pointer; }
  @media (max-width: 768px) {
    .navigation__menu {
      position: fixed;
      padding-top: 106px;
      padding-left: 50px;
      z-index: 2;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.9);
      display: none; } }
  @media (min-width: 768px) {
    .navigation__menu {
      display: unset !important; } }

.navigation__copyright {
  opacity: 0.5; }
  @media (max-width: 768px) {
    .navigation__copyright {
      display: none; } }

.navigation__hamburger {
  z-index: 1; }
  .navigation__hamburger.hamburger {
    order: 2; }
  .navigation__hamburger.x {
    position: absolute;
    z-index: 2;
    right: 32px;
    top: 35px; }
  @media (min-width: 768px) {
    .navigation__hamburger {
      display: none; } }

.bg--top {
  mix-blend-mode: lighten; }
  @media (max-width: 768px) {
    .bg--top {
      overflow: hidden; } }
  .bg--top img {
    max-width: 100%;
    height: auto; }

.bg--bottom {
  top: 0;
  bottom: 0;
  background-size: cover;
  background-image: url("../assets/bg/bg1@2x.jpg"); }

section.main__header {
  max-width: 800px;
  margin-bottom: 247px;
  flex: 1;
  padding-top: 142px;
  padding-left: 198px;
  margin-left: 295px; }
  section.main__header.--duties {
    margin-bottom: 149px; }
  @media (max-width: 1280px) {
    section.main__header {
      padding-left: 98px; } }
  @media (max-width: 1073px) {
    section.main__header {
      padding-left: 38px; } }
  @media (max-width: 768px) {
    section.main__header {
      padding-left: 0;
      margin-left: 0;
      margin-top: 78px;
      margin-bottom: 151px; } }

.main__header h1 {
  margin-bottom: 45px;
  padding-right: 10px; }
  @media (max-width: 768px) {
    .main__header h1 {
      margin-bottom: 23px; } }

.main__about-button,
.main__career-button {
  cursor: pointer;
  position: relative;
  height: 54px;
  padding: 0 29px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px; }

section.middle {
  padding-bottom: 162px;
  position: relative; }
  @media (max-width: 768px) {
    section.middle {
      margin-top: 0; } }
  section.middle .about-bg,
  section.middle .duties-bg {
    height: calc(100% + 120px);
    width: 300%;
    position: absolute;
    z-index: -1;
    background-image: url("../assets/bg/bg_gradient.png");
    background-size: contain;
    background-repeat: repeat-x;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    left: -134px;
    background-position-y: -1px; }
    @media (max-width: 1280px) {
      section.middle .about-bg,
      section.middle .duties-bg {
        left: -84px; } }
    @media (max-width: 768px) {
      section.middle .about-bg,
      section.middle .duties-bg {
        background: rgba(11, 11, 19, 0);
        margin-top: 0;
        top: 33px;
        left: 0;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        width: 100%;
        height: calc(100% - 33px); } }
  @media (max-width: 768px) {
    section.middle {
      left: 0; } }

.duties .middle {
  padding-bottom: 317px; }
  @media (max-width: 768px) {
    .duties .middle {
      padding-bottom: 179px; } }

section.main__about,
section.main__duties {
  margin-left: 400px;
  position: relative; }
  @media (max-width: 1073px) {
    section.main__about,
    section.main__duties {
      margin-left: 240px; } }
  @media (max-width: 1280px) {
    section.main__about,
    section.main__duties {
      margin-left: 300px; } }
  @media (max-width: 768px) {
    section.main__about,
    section.main__duties {
      margin: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 74px 30px 33px;
      overflow: hidden; } }
  section.main__about .about-header,
  section.main__about .duties-header,
  section.main__duties .about-header,
  section.main__duties .duties-header {
    display: flex;
    align-items: center;
    position: relative;
    top: -32px; }
    section.main__about .about-header div,
    section.main__about .duties-header div,
    section.main__duties .about-header div,
    section.main__duties .duties-header div {
      flex: 1;
      border-top: 1px solid rgba(255, 255, 255, 0.4); }
    section.main__about .about-header h2,
    section.main__about .duties-header h2,
    section.main__duties .about-header h2,
    section.main__duties .duties-header h2 {
      margin-left: 17px;
      margin-right: 15px; }
  section.main__about .section-title.desktop,
  section.main__duties .section-title.desktop {
    position: relative;
    top: -32px; }
    @media (max-width: 768px) {
      section.main__about .section-title.desktop,
      section.main__duties .section-title.desktop {
        display: inline;
        text-align: center;
        margin: 0 auto;
        top: unset; } }
    section.main__about .section-title.desktop::after, section.main__about .section-title.desktop::before,
    section.main__duties .section-title.desktop::after,
    section.main__duties .section-title.desktop::before {
      position: absolute;
      content: "";
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4); }
    section.main__about .section-title.desktop::before,
    section.main__duties .section-title.desktop::before {
      width: 110px;
      top: 32px;
      left: -134px; }
      @media (max-width: 1280px) {
        section.main__about .section-title.desktop::before,
        section.main__duties .section-title.desktop::before {
          width: 60px;
          left: -84px; } }
      @media (max-width: 768px) {
        section.main__about .section-title.desktop::before,
        section.main__duties .section-title.desktop::before {
          width: 400px;
          left: -420px; } }
    section.main__about .section-title.desktop::after,
    section.main__duties .section-title.desktop::after {
      width: 100vw;
      top: 32px;
      left: 100px; }
      @media (max-width: 768px) {
        section.main__about .section-title.desktop::after,
        section.main__duties .section-title.desktop::after {
          width: 400px; } }
  section.main__about .about__segment,
  section.main__duties .about__segment {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 130px; }
    @media (min-width: 768px) {
      section.main__about .about__segment,
      section.main__duties .about__segment {
        margin-right: 32px; } }
    @media (max-width: 1440px) {
      section.main__about .about__segment,
      section.main__duties .about__segment {
        flex-wrap: nowrap; } }
    @media (max-width: 1280px) {
      section.main__about .about__segment,
      section.main__duties .about__segment {
        justify-content: center;
        margin-top: 0; } }
    @media (max-width: 768px) {
      section.main__about .about__segment,
      section.main__duties .about__segment {
        flex-wrap: wrap; } }
  section.main__about .about__segment-text,
  section.main__duties .about__segment-text {
    max-width: 400px; }
  section.main__about .about__segment-image,
  section.main__duties .about__segment-image {
    margin-left: 135px;
    margin-right: 135px; }
    @media (max-width: 1280px) {
      section.main__about .about__segment-image,
      section.main__duties .about__segment-image {
        margin: 50px; } }
    @media (max-width: 768px) {
      section.main__about .about__segment-image,
      section.main__duties .about__segment-image {
        order: -1; } }

section.lower {
  position: relative; }

.main__careers {
  position: relative;
  padding-bottom: 131px;
  margin-left: 400px;
  padding-top: 240px; }
  @media (max-width: 1073px) {
    .main__careers {
      margin-left: 240px; } }
  @media (max-width: 1280px) {
    .main__careers {
      padding-top: 0;
      margin-left: 300px; } }
  @media (max-width: 768px) {
    .main__careers {
      margin-left: 0; } }

.careers__intro {
  max-width: 445px;
  margin-bottom: 73px; }

.careers__job {
  position: relative;
  width: 160px;
  height: 193px; }
  @media (max-width: 1440px) {
    .careers__job {
      margin-bottom: 50px; } }
  .careers__job .arrow-icon polygon {
    transition: 0.2s; }
  .careers__job g,
  .careers__job path {
    fill: rgba(255, 255, 255, 0.5); }
  .careers__job:hover path {
    fill: #71c3ed; }
  .careers__job:hover .arrow-icon polygon {
    fill: #71c3ed; }
  .careers__job .careers__job-bg {
    position: absolute; }
    .careers__job .careers__job-bg svg {
      transition: 300ms;
      transition-timing-function: ease-in-out;
      -webkit-transition: 300ms;
      -webkit-transition-timing-function: ease-in-out; }
  .careers__job .careers__job-title {
    transition: all 300ms; }
  .careers__job.careers__job--one {
    left: -45px; }
    @media (max-width: 1280px) {
      .careers__job.careers__job--one {
        left: 0; } }
  .careers__job.careers__job--two {
    left: 402px;
    top: -121px; }
    @media (max-width: 1280px) {
      .careers__job.careers__job--two {
        left: 52px;
        top: unset; } }
    @media (max-width: 320px) {
      .careers__job.careers__job--two {
        left: 25px; } }
  .careers__job.careers__job--three {
    left: 120px;
    top: -131px; }
    @media (max-width: 1280px) {
      .careers__job.careers__job--three {
        left: 0;
        top: unset; } }

.careers__job-title {
  position: relative;
  top: 42px;
  left: 42px; }

@media (max-width: 910px) {
  .careers__job--one .careers__job-title,
  .careers__job--two .careers__job-title {
    top: 29px; } }

section.bottom {
  padding-top: 160px;
  position: relative;
  padding-bottom: 70px; }
  @media (max-width: 1280px) {
    section.bottom {
      padding-top: 52px;
      padding-bottom: 0; } }

.bottom__header-wrapper {
  width: 100%;
  position: absolute;
  height: 1px;
  top: 0; }
  @media (max-width: 768px) {
    .bottom__header-wrapper {
      overflow-x: hidden;
      width: calc(100% - 32px); } }
  .bottom__header-wrapper span::after, .bottom__header-wrapper span::before {
    opacity: 0.4;
    position: absolute;
    content: "";
    height: 1px;
    background-color: white; }
  .bottom__header-wrapper span::before {
    width: 110px;
    left: -134px; }
    @media (max-width: 768px) {
      .bottom__header-wrapper span::before {
        display: none; } }
  .bottom__header-wrapper span::after {
    width: 100%;
    left: 176px; }

.main__contact {
  margin-left: 400px; }
  @media (max-width: 1073px) {
    .main__contact {
      margin-left: 240px; } }
  @media (max-width: 1280px) {
    .main__contact {
      margin-left: 300px; } }
  @media (max-width: 768px) {
    .main__contact {
      margin-left: unset; } }
  .main__contact .section-title {
    display: inline;
    position: absolute;
    top: -33px; }

.contact__field {
  position: relative;
  width: 142px;
  height: 142px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  @media (max-width: 768px) {
    .contact__field {
      left: 0;
      top: unset; } }
  @media (max-width: 350px) {
    .contact__field {
      transform: scale(0.85); } }
  .contact__field:hover .contact__field-bg g {
    opacity: 1; }
  .contact__field:hover .contact__field-bg path {
    fill-opacity: 1;
    fill: #71c3ed; }
  .contact__field .contact__field-bg {
    position: absolute;
    top: 0;
    left: 0; }
    .contact__field .contact__field-bg svg {
      transition: 300ms;
      transition-timing-function: ease-in-out;
      -webkit-transition: 300ms;
      -webkit-transition-timing-function: ease-in-out;
      transition: all 0.2s; }
  .contact__field .contact__info {
    position: absolute;
    left: 106px;
    top: 56px;
    white-space: nowrap;
    transition: all 300ms; }

.contact__icon svg {
  transition: 300ms;
  transition-timing-function: ease-in-out;
  -webkit-transition: 300ms;
  -webkit-transition-timing-function: ease-in-out; }

@media (max-width: 1280px) {
  .contact__field--mail {
    width: auto;
    height: auto;
    justify-content: flex-start;
    margin-bottom: 25px; }
    .contact__field--mail img {
      margin-right: 26px; }
    .contact__field--mail .contact__info {
      position: inherit;
      top: 0;
      left: 0; }
    .contact__field--mail svg {
      display: none; } }

.contact__field--phone {
  left: 389px;
  top: 50px; }
  @media (max-width: 1280px) {
    .contact__field--phone {
      justify-content: flex-start;
      width: auto;
      height: auto;
      left: 0;
      top: auto;
      margin-bottom: 50px; }
      .contact__field--phone img {
        margin-right: 26px; }
      .contact__field--phone .contact__info {
        position: inherit;
        top: 0;
        left: 0; }
      .contact__field--phone svg {
        display: none; } }

.contact__field--facebook {
  top: auto;
  left: 93px; }
  @media (max-width: 1280px) {
    .contact__field--facebook {
      left: 0; } }

.contact__field--google {
  top: 30px;
  left: 293px; }
  @media (max-width: 1280px) {
    .contact__field--google {
      top: -67px;
      left: 170px; } }
  @media (max-width: 350px) {
    .contact__field--google {
      left: 145px; } }

.contact__field--linkedin {
  top: -39px;
  left: -4px; }
  @media (max-width: 1280px) {
    .contact__field--linkedin {
      top: -105px;
      left: 0; } }
  @media (max-width: 350px) {
    .contact__field--linkedin {
      top: -135px; } }

.career_intro {
  margin-top: 10px;
  max-width: 600px;
  padding-right: 40px; }

.duties__duty {
  max-width: 700px;
  margin-bottom: 37px;
  padding-right: 25px;
  display: flex;
  align-items: flex-start; }
  .duties__duty img {
    position: relative;
    top: 3px;
    margin-right: 25px; }

.main__header.--duties h1 {
  margin-bottom: 0; }

.main__header.--duties span {
  position: relative;
  top: -1em; }

.main__career-button {
  margin-top: 37px; }

.copyright {
  position: absolute;
  bottom: 62px; }

#career {
  position: absolute;
  top: 150px; }
  @media (max-width: 768px) {
    #career {
      top: -50px; } }

#aboutf3ea {
  position: absolute;
  top: -80px; }
